import React from "react";

import Layout from "../containers";
import { SEO } from "../components/elements";
import useFirebaseAnalytics from "../hooks/useFirebaseAnalytics";

const NotFoundPage = ({ location }) => {
  const title = "404: Not found";
  useFirebaseAnalytics(`PAGE: 404 ${location.pathname}`);
  return (
    <Layout>
      <SEO title={title} />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
};

export default NotFoundPage;
